import React from "react";
import { insidePolygon, Location } from 'geolocation-utils'


import "./Localization.scss";

enum LocalizationState {
    Start, Error, Pending, Ok, NotGranted, NotInArea
}

interface ILocalizationState {
    localizationState: LocalizationState
}

interface ILocalizationProps {
    localizationOk: () => void
}

export class Localization extends React.Component<ILocalizationProps, ILocalizationState> {

    constructor(props: ILocalizationProps) {
        super(props);
        this.state = {
            localizationState: LocalizationState.Start
        }
    }

    onGetLocationClick = async () => {
        let geolocationPermission = await navigator.permissions.query({ name: 'geolocation' });
        if (geolocationPermission.state == "denied") {
            this.setState({ localizationState: LocalizationState.NotGranted });
            return;
        }

        this.setState({ localizationState: LocalizationState.Pending });
        navigator.geolocation.getCurrentPosition(async (position) => {
            if (await this.isInsidePolygon(position.coords.latitude, position.coords.longitude)) {
                this.setState({ localizationState: LocalizationState.Ok });
            } else {
                this.setState({ localizationState: LocalizationState.NotInArea });
            }
        }, () => {
            this.setState({ localizationState: LocalizationState.Error });
        }, { enableHighAccuracy: true, maximumAge: 5000 });
    }

    isInsidePolygon = async (lat: any, lng: any) => {
        let data = await (await fetch("/config.json")).json();
        if (data.geofence.length > 0) {
            return insidePolygon(([lng, lat] as any), (data.geofence as any));
        }
        return true;
    }

    render() {
        return (<div className="localization-view">
            <div className="text-center">
                {this.state.localizationState == LocalizationState.Start && <>
                    <h3>Potrzebujemy Twojej lokalizacji w celu sprawdzenia, czy znajdujesz się przy fontannie.</h3>
                    <button onClick={() => this.onGetLocationClick()} className="btn btn-primary mt-4">Udostępnij lokalizację</button>
                </>}
                {this.state.localizationState == LocalizationState.NotGranted && <>
                    <h3>Niestety Twoja przeglądarka nie chce udostępnić nam lokalizacji. Zmień jej ustawienia i wróć do nas.</h3>
                    <button onClick={() => this.onGetLocationClick()} className="btn btn-primary mt-4">Udostępnij lokalizację</button>
                </>}
                {this.state.localizationState == LocalizationState.Ok && <>
                    <h3>Wszystko w porządku. Jesteś przy fontannie.</h3>
                    <h4 className="mt-4"><div className="volume-info">Zwiększ głośność w telefonie dla lepszego efektu!</div>
                        <div className="volume-info">Trzymaj ekran cały czas włączony.</div></h4>
                    <button onClick={() => this.props.localizationOk()} className="btn btn-primary mt-4">Idę dalej</button>
                </>}
                {this.state.localizationState == LocalizationState.NotInArea && <>
                    <h3>Lokalizacja została pobrana, ale nie znajdujesz się w okolicy fontanny.</h3>
                    <button onClick={() => this.onGetLocationClick()} className="btn btn-primary mt-4">Udostępnij lokalizację</button>
                </>}
                {this.state.localizationState == LocalizationState.Pending && <>
                    <h3>Pobieramy lokalizację...</h3>
                    <button onClick={() => this.onGetLocationClick()} className="btn btn-primary mt-4">Udostępnij lokalizację</button>
                </>}
                {this.state.localizationState == LocalizationState.Error && <>
                    <h3>Nie możemy pobrać lokalizacji. Spróbuj jeszcze raz.</h3>
                    <button onClick={() => this.onGetLocationClick()} className="btn btn-primary mt-4">Udostępnij lokalizację</button>

                </>}
            </div>
        </div>)
    }
}

export default Localization;